import React from "react";
import { Link } from "react-router";
import Header from "../components/header";
import Footer from "../components/footer";
import posts from "../database/blogs";
import RecommendedArticle from "../components/recommendedArticle";

const About = () => (
  <div>
    <link rel="canonical" href="https://www.growthdiary.blog/about" />
    <meta name="og:url" content="https://www.growthdiary.blog/about" />
    <meta name="og:title" content="About" />
    <meta
        name="og:description"
        content="Learn about blogging, eCommerce, entrepreneurship, SEO, content marketing and being successful in life from a how to make money online pioneer"
      />
      <meta
        name="description"
        content="Learn about blogging, eCommerce, entrepreneurship, SEO, content marketing and being successful in life from a how to make money online pioneer"
      />
    <title>How To Make Money Online - Get Started With GrowthDiary</title>
    <Header />
    <main>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 ">
              <div className="breadcrumbs mb-4">
                {" "}
                <a href="/">Home</a>
                <span className="mx-1">/</span> <a href="about.html">About</a>
              </div>
            </div>
            <div className="col-lg-8 mx-auto mb-5 mb-lg-0">
              <img
                loading="lazy"
                decoding="async"
                src={require("../assets/images/author.webp")}
                className="img-fluid w-100 mb-4"
                alt="Author Image"
              />
              <h1 className="mb-4">Growth Diary</h1>
              <div className="content">
                <p>
                  Welcome to Growth Diary, your trusted ally on the road to
                  personal, professional, and financial success. We’re more than
                  just a blog—we’re a vibrant hub for ambitious individuals
                  eager to elevate their lives. Our mission is to equip you with
                  the tools, insights, and inspiration to unlock your true
                  potential. With a focus on entrepreneurship, personal
                  development, and online income, our authentic and
                  well-researched content empowers you to achieve continuous
                  growth. Join our community of dreamers and doers, and discover
                  how you can transform ambitions into achievements, one step at
                  a time.
                </p>
                <blockquote>
                  <p>
                    To empower ambitious individuals with the knowledge and
                    tools they need to unlock their potential and achieve
                    personal, professional, and financial success.
                  </p>
                </blockquote>
                <blockquote>
                  <p>
                    To be the ultimate resource hub for dreamers, doers, and
                    go-getters, inspiring continuous growth and transformation
                    in all aspects of life.
                  </p>
                </blockquote>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="widget-blocks">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="widget">
                      <h2 className="section-title mb-3">Recommended</h2>
                      <div className="widget-body">
                        <div className="widget-list">
                          <article className="card mb-4">
                            <div className="card-image">
                              <div className="post-info">
                                {" "}
                                <span className="text-uppercase">
                                  3 minutes read
                                </span>
                              </div>
                              <img
                                loading="lazy"
                                decoding="async"
                                src={posts[1].image}
                                alt="Post Thumbnail"
                                className="w-100"
                              />
                            </div>
                            <div className="card-body px-0 pb-1">
                              <h3>
                                <a
                                  className="post-title post-title-sm"
                                  href={`/${posts[1].id}`}
                                >
                                  {posts[1].title}
                                </a>
                              </h3>
                              <p className="card-text">{posts[1].subtitle}</p>
                              <div className="content">
                                {" "}
                                <a
                                  className="read-more-btn"
                                  href={`/${posts[1].id}`}
                                >
                                  Read Full Article
                                </a>
                              </div>
                            </div>
                          </article>
                          {posts.slice(6, 8).map(function (post, index) {
                            return (
                              <RecommendedArticle post={post} key={index} />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                      <div className="widget">
                        <h2 className="section-title mb-3">Categories</h2>
                        <div className="widget-body">
                          <ul className="widget-list">
                            <li>
                              <a href="/category/make-money">
                                make-money<span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="/category/web-traffic">
                                web-traffic<span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="/category/entrepreneurship">
                                entrepreneurship
                                <span className="ml-auto"></span>
                              </a>
                            </li>
                            <li>
                              <a href="category/business-inspiration">
                                business-inspiration
                                <span className="ml-auto"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </div>
);

export default About;
